import React from 'react';
import { Box, Typography } from '@heycater/design-system';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export default function CompareSection() {
  const { t } = useTranslation('homepage');

  return (
    <Wrapper>
      <Title>{t('compareSection.title')}</Title>
      <Box mt={3}>
        <Head>{t('compareSection.section1.head')}</Head>
        <Body>{t('compareSection.section1.subtitle')}</Body>
      </Box>
      <Box mt={4}>
        <Head>{t('compareSection.section2.head')}</Head>
        <Body>{t('compareSection.section2.subtitle')}</Body>
      </Box>
      <Box mt={4}>
        <Head>{t('compareSection.section3.head')}</Head>
        <Body>{t('compareSection.section3.subtitle1')}</Body>
        <Body mt={3}>{t('compareSection.section3.subtitle2')}</Body>
      </Box>
    </Wrapper>
  );
}

const Title = styled(Typography)`
  font-size: 20px;
  line-height: 34.5px;
  font-weight: 700;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 36px;
    line-height: 62.1px;
  }
`;

const Head = styled(Typography)`
  font-size: 16px;
  font-weight: 700;
  line-height: 26.88px;
`;

const Body = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
  line-height: 26.88px;
`;

const Wrapper = styled.div`
  margin: auto;
  padding: ${({ theme }) => theme.spacing(0, 2)};
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 808px;
    padding: 0;
  }
`;
